export default class System {
  id?: number;
  name!: string;
  url!: string;
  keyId!: string;
  keySecret?: string;
  active = true;
  showImprovement = false;
  createdAt?: Date;
  updatedAt?: Date;
  countryId!: number;
}
